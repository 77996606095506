import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import $ from 'jquery'
import { Editor } from "@tinymce/tinymce-react";
import {
  Typography,
  Button,
} from "@material-ui/core";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {fetcher} from '../../utils/fetchBData'

const styledeco = {
    display: 'block',
    width: '100%',
    padding: '.375rem .75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
}

export default function EditNotification() {
   const [resp, setResp] = useState({});
   const [inccon, setIncContent] = useState("");
   const [emailsub, setEmailSubject] = useState("");
   const [emailcon, setEmailContent] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const handleReq = (id, title, requestOptions, url) => {
        setIsLoading(true)
          fetcher(url, requestOptions)
            .then(data => {
            setIsLoading(false)
            setResp(data)
            setIncContent(data.content)
            setEmailSubject(data.emailsubject)
            setEmailContent(data.emailcontent)
            let msg = data.message;
            let msgtype = 'warning';
            if (data.success === 1){
                msg = data.message;
                msgtype = 'success';
            }

            Store.addNotification({
              title: title,
              message: msg,
              type: msgtype,
              insert: "top",
              container: "top-center",
              width: 500,
              dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
              }
          });

          }
          )
          .catch(error => {
            console.error('There was an error!', error);
          })
   }

   const updateFunc = (updatedata, e) => {
        //e.preventDefault();
        var ed = window.tinymce.activeEditor;
        $('#hidContents').val(ed.getContent({'format': 'raw'}));
        $('#sendnow').val("0");
        $('#viewpdf').val("0");
        const requestOptions = {
        method: 'POST',
        body: JSON.stringify({'inccontent': inccon, 'sendnow': $('#sendnow').val(), 'viewpdf': $('#viewpdf').val(), 'emailsubject': $('#emailsubject').val(), 'emailcontent': $('#emailcontent').val(), 'customer': $('#customer').val(), 'tmpcustomername': $('#tmpcustomername').val()}),
        };
        let url = process.env.REACT_APP_CONTRIVE_API_URL+"edit_notification/"+updatedata;
        console.log(url);
        handleReq(updatedata, 'Updated!', requestOptions, url);
       }
 
   const onViewpdfFunc = (data, e) => {
     e.preventDefault();
     window.open("/#/viewpdf?pdf="+data+"&loc=notification", "_blank");
   }

    
   const sendtocustomerFunc = (id, e) => {
            e.preventDefault();
      var custchk = $('#customer').val();
      var emailschk = $('#emailsubject').val();
      var emailcchk = $('#emailcontent').val();
      if (custchk == ""){
	      alert("Please select customer.");
	      //document.getElementById("customer").focus();
              $('#customer').focus();
	      return false;
      }
      if (emailschk == ""){
	      alert("Please enter email subject.");
	      //document.getElementById("emailsubject").focus();
	      $("#emailsubject").focus();
	      return false;
      }
      if (emailcchk == ""){
	      alert("Please enter email content.");
	      //document.getElementById("emailcontent").focus();
	      $("#emailcontent").focus();
	      return false;
      }
      var selcust = document.getElementById("customer");
      var custtext= selcust.options[selcust.selectedIndex].text;
      $("#tmpcustomername").val(custtext);

            var ask = window.confirm("Are you sure you want to send this Notification to "+custtext+"?");
            if (ask) {
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({'inccontent': inccon, 'sendnow': "1", 'viewpdf': "0", 'emailsubject': $('#emailsubject').val(), 'emailcontent': $('#emailcontent').val(), 'customer': $('#customer').val(), 'tmpcustomername': $('#tmpcustomername').val()}),
                };
                let url = process.env.REACT_APP_CONTRIVE_API_URL+"edit_notification/"+id;
                handleReq(id, 'Sent!', requestOptions, url);
            }
            else {
           return false;
           }
   window.location.href = "/#/notifications";
   }
 
   const handleEditorChange = (content, editor) => {
     setIncContent(content);
   }

   const search = useLocation().search;
   const editid = new URLSearchParams(search).get("id");
   const unsignedUploadPreset = 'adz8s31b';  
   const fetchData = (id) => {
    setIsLoading(true)
    let url = process.env.REACT_APP_CONTRIVE_API_URL+"edit_notification/"+id;
    fetcher(url, {})
      .then(data => {
        setIsLoading(false)
        setResp(data)
        setIncContent(data.content)
        setEmailSubject(data.emailsubject)
        setEmailContent(data.emailcontent)
      })
      .catch(error => {
            console.error('There was an error!', error);
  })
  }
  useEffect(() => {
       fetchData(editid);
  }, []);
  return (
    <div className="App">
      {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : ( 
      <React.Fragment>
      <Typography variant="h2">Send {resp.notification_type} notification</Typography>
      </React.Fragment>
      )}
        {isLoading ? (
        null //<p><CircularProgress color="inherit" /></p> // no circular progress as coming from abv
      ) : (
      <React.Fragment>
      <form method="POST" id="editform" name="editform">

      <label for="customers">Select Customer:&nbsp; </label><select style={styledeco} name="customer" id="customer">
    <option value="">--Select Customer--</option>
    <option value="testing">Testing</option>
    <option value="OTSL-Test">OTSL-Test</option>
    { resp.customers && resp.customers.length ?
    (resp.customers.map((cust) => {
         if (cust['id'] == resp.selectedcus) 
             return <option value={cust['id']} selected>{cust['name']}</option>
         else
             return <option value={cust['id']} >{cust['name']}</option>
          
    })) : null
    })
    }
    </select>
     <div>Email Subject: <input type="text" style={styledeco} id="emailsubject" name="emailsubject" value={emailsub} onChange={e => setEmailSubject(e.target.value)} /></div>
    <div>Email Content: <textarea id="emailcontent" style={styledeco} name="emailcontent" rows="3" cols="80" value={emailcon} onChange={e => setEmailContent(e.target.value)} /></div>
      Report Content:<Editor
        apiKey="qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"
        initialValue={resp.content}
        onEditorChange={handleEditorChange}
        init={{
        height: 600,
        toolbar: "code insertfile undo redo image | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link imageupload",
        plugins: "advlist autolink lists link image charmap print anchor searchreplace visualblocks code fullscreen code insertdatetime media table contextmenu paste",
        init_instance_callback : function() {
        var freeTiny = document.querySelector('.tox .tox-notification--in');
        freeTiny.style.display = 'none';
        //var freeTiny1 = document.querySelector('.tox-notification .tox-notification--in .tox-notification--warning');
        //freeTiny1.style.display = 'none';
        },
        forced_root_block:"",
        images_upload_credentials: true,
        image_title: true,
        automatic_uploads: true,
        file_picker_types: 'image',
        file_picker_callback: function (cb, value, meta) {
              var input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');
              var url = process.env.REACT_APP_CONTRIVE_API_URL+"image_uploader";
              var xhr = new XMLHttpRequest();
              var fd = new FormData();
              xhr.open('POST', url, true);

              input.onchange = function () {
                var file = this.files[0];
                var reader = new FileReader();
                xhr.onload = function () {
                  if (xhr.status === 200) {
                    // File uploaded successfully
                    var response = JSON.parse(xhr.responseText);
                    var url = response.location;
                    cb(url, { title: 'tmp.png' });

                  }
                };

                reader.onload = function () {
                  var id = 'blobid' + (new Date()).getTime();
                  var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                  var base64 = reader.result.split(',')[1];

                  var blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);
                  // call the callback and populate the Title field with the file name
                  fd.append('upload_preset', unsignedUploadPreset);
                  fd.append('tags', 'browser_upload');
                  fd.append('file', blobInfo.blob(), blobInfo.filename());

                  xhr.send(fd);

                };

                reader.readAsDataURL(file);
              };

              input.click();
            },
            images_upload_handler: (blobInfo, success, failure) => {
              let data = new FormData();
              var reader = new FileReader();
              var url = process.env.REACT_APP_CONTRIVE_API_URL+"image_uploader";
              data.append('file', blobInfo.blob(), blobInfo.filename());
              data.append('upload_preset', unsignedUploadPreset);
              data.append('tags', 'browser_upload');
              axios.post(url, data, { headers: { 'Content-Type': 'multipart/form-data'}
                 })
                .then(function (res) {
                  //success(res.data.location)
                })
                .catch(function (err) {
                  //console.log(err)
                });
              reader.readAsDataURL(blobInfo.blob())
            }
      }}
      />
      <input id="hidContents" type="hidden" name="hidContents"/>
    <input type="hidden" name="sendnow" id="sendnow" value="0"/>
    <input type="hidden" name="viewpdf" id="viewpdf" value="0"/>
    <input type="hidden" name="tmpcustomername" id="tmpcustomername" value=""/>
      <br />
        <Button title="Update Template" onClick={(e) => updateFunc(editid, e)}  variant="contained" color="primary">
          Update Template 
        </Button>
        &nbsp;&nbsp;<Button title="View Pdf" onClick={(e) => onViewpdfFunc(editid, e)} variant="contained" color="primary">
          View PDF
        </Button>
        &nbsp;&nbsp;<Button title="Send notification report to customer" onClick={(e) => sendtocustomerFunc(editid, e)} variant="contained" color="primary">
          Send to Customer
        </Button>
    </form>
    </React.Fragment>
    )}
    </div>
  );
}


