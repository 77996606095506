import React , { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import dateFormat from "dateformat";
import { Store } from 'react-notifications-component'

// components
import PageTitle from "../../components/PageTitle";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from '@mui/icons-material/Delete';

import {fetcher} from '../../utils/fetchBData'
// msal auth
import { useMsal} from "@azure/msal-react"
import {get_access_txt, check_read_only} from '../../utils/Utils';

import {
  PictureAsPdf as PictureAsPdfIcon,
} from "@material-ui/icons";

import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
const classes = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

export default function Tables() {
  const [incs, setInc] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { accounts, instance } = useMsal();
  var read_only_flag = check_read_only(accounts);
  var allow_edit = read_only_flag ? false : true;
  const handleReq = (id, title, requestOptions, url, shownotification=true) => {

        setIsLoading(true)
        //let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/edit/"+id;
          fetcher(url, requestOptions)
            .then(data => {
            setIsLoading(false)
            setInc(data)
            let msg = data.message;
            let msgtype = 'warning';
            if (data.success === 1){
                msg = data.message;
                msgtype = 'success';
            }
            if (shownotification) {
            Store.addNotification({
              title: title,
              message: msg,
              type: msgtype,
              insert: "top",
              container: "top-center",
              width: 500,
              dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
              }
          });
          }
          }
          )
          .catch(error => {
            console.error('There was an error!', error);
          })
   }

   const confirmmoveFunc = (id, e) => {
      e.preventDefault();
      var ask = window.confirm("Are you sure you want to move "+id+" Incident to Queued Incidents?");
      if (ask) {
          //window.location.href = "/discardincidents/move/"+id;
          let url = process.env.REACT_APP_CONTRIVE_API_URL+"discardincidents/move/"+id;
          handleReq(id, 'Moved!', {}, url)
      }else {
      return false;
      }
    }

  const confirmcheckmove = (checkedarray, rowdata) => {
      var ask = window.confirm("Are you sure you want to move selected Incidents to Queued Incidents?");
      if (ask) {
          for (let i = 0; i < checkedarray.data.length; i++) {
            var shownotification = false;
            if (i === checkedarray.data.length - 1) {
                shownotification = true;
            }
            var id = rowdata[checkedarray.data[i]['dataIndex']]['idhash']
            let url = process.env.REACT_APP_CONTRIVE_API_URL+"discardincidents/move/"+id;
            handleReq(id, 'Moved!', {}, url, shownotification)
        }
        } else {
            return false;
        }
    }
  
  const confirmcheckdel = (checkedarray, rowdata) => {
      var ask = window.confirm("Are you sure you want to delete selected Incidents?");
      if (ask) { 
          for (let i = 0; i < checkedarray.data.length; i++) {
            var shownotification = false;
            if (i === checkedarray.data.length - 1) {
                shownotification = true;
            }
            var id = rowdata[checkedarray.data[i]['dataIndex']]['idhash']
            let url = process.env.REACT_APP_CONTRIVE_API_URL+"discardincidents/confirm/"+id;
            handleReq(id, 'Deleted!', {}, url, shownotification)
        }
        } else {
            return false;
        }
    }

  const columns = [
 {
  name: "idhash",
  label: "Id",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href={"/#/viewpdf?pdf="+value+"&loc=discard"} title={value} target="_blank" rel="noreferrer">{value.substring(0, 4)+"..."}</a>
          </div>
        ) 
  }
 },
 {
  name: "signature",
  label: "EventName",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "customer",
  label: "Customer",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "severity",
  label: "Severity",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "trigger_at",
  label: "TriggerDate",
  options: {
   filter: true,
   sort: false,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
           {value} 
          </div>
        )
  }
 },
 {
  name: "activity_at_ts",
  label: "DiscardDate",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <p>
            {dateFormat(value*1000, "yyyy-mm-dd hh:mm:ss")}
          </p>
        )
  }
 },
 {
  name: "idhash",
  label: "View Pdf",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href={"/#/viewpdf?pdf="+value+"&loc=discard"} target="_blank" rel="noreferrer"><PictureAsPdfIcon /></a>
          </div>
        )
          }
  },
/** {
  name: "idhash",
  label: "Move",
  options: {
   filter: true,
   sort: false,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href='#' onClick ={(e) => confirmmoveFunc(value, e)}><DriveFileMoveIcon /></a>
          </div>
        )
          }
  }
**/
];

  const fetchData = () => {
    setIsLoading(true)
    let url = process.env.REACT_APP_CONTRIVE_API_URL+"discardincidents";
    let data = fetcher(url, {}).then(data => {
    setInc(data)
    setIsLoading(false)
    });
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
      <PageTitle title="Discard Incidents View" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : (
          <MUIDataTable
            title="Discard Incident List"
            data={incs.incidents}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: allow_edit,
              customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <div className={classes.iconContainer}>
                  <Tooltip title={"Move Incidents"}>
                    <IconButton className={classes.iconButton} onClick={(e) =>confirmcheckmove(selectedRows, incs.incidents)}>
                      <DriveFileMoveIcon className={classes.icon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Delete Incidents"}>
                    <IconButton className={classes.iconButton} onClick={(e) =>confirmcheckdel(selectedRows, incs.incidents)}>
                      <DeleteIcon className={classes.icon} />
                    </IconButton>
                  </Tooltip>
                </div>
    ),
              onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      },
      onRowsDelete: (rowsDeleted, data, newTableData) => {
        var ask = window.confirm("Are you sure you want to delete selected Incidents?");
      if (ask) {
        confirmcheckdel(rowsDeleted, incs.incidents);
        } else {
        return false;
        }
      }
            }}
          />
          )}
        </Grid>
      </Grid>
    </>
  );
}
