import React , { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import {fetcher} from '../../utils/fetchBData'

// components
import PageTitle from "../../components/PageTitle";

import {get_access_txt} from '../../utils/Utils';

const columns = [
 {
  name: "signature",
  label: "Signature",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "customer",
  label: "Customer",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "severity",
  label: "Severity",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "src_ip",
  label: "Source Ip",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {(tableMeta.rowData[3] != "") ?
            value
            : 'N/A'}
          </div>
        )
  }
 },
 {
  name: "dest_ip",
  label: "Dest Ip",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {(tableMeta.rowData[4] != "") ?
            value
            : 'N/A'}
          </div>
        )
  }
 },
 {
  name: "count",
  label: "Count",
  options: {
   filter: true,
   sort: true,
  }
 }
];

export default function Tables() {
  const [incs, setInc] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchData = () => {
    setIsLoading(true)
    let url = process.env.REACT_APP_CONTRIVE_API_URL+"aggincidents";
    const requestOptions = {};
    let data = fetcher(url, requestOptions).then(data => {
      setInc(data)
      setIsLoading(false)
    });
    }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
      <PageTitle title="Aggregated View" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <React.Fragment>
          {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : (
          <MUIDataTable
            title="Aggregated Queued Inc List"
            data={incs}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: false,
            }}
          />
         )}
    </React.Fragment>
        </Grid>
      </Grid>
    </>
  );
}
