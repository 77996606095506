import React , { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import CustomToolbar from "./CustomToolbar";
import EditIcon from '@mui/icons-material/Edit';
import { Store } from 'react-notifications-component'
import {fetcher} from '../../utils/fetchBData'

// msal auth
import { useMsal} from "@azure/msal-react"
import {get_access_txt, check_read_only} from '../../utils/Utils';

// components
import PageTitle from "../../components/PageTitle";

const handleEdit = (id, e) => {
    e.preventDefault();
    window.location = "/#/addregion?id="+id;
  }

export default function Tables() {
  const [regions, setRegions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { accounts, instance } = useMsal();
  var read_only_flag = check_read_only(accounts);
  var allow_edit = read_only_flag ? false : true;
  let url = process.env.REACT_APP_CONTRIVE_API_URL+"region";

  const handleReq = (id, title, requestOptions, url, shownotification=true) => {

        setIsLoading(true)
          fetcher(url, requestOptions)
            .then(data => {
            setRegions(data)
            setIsLoading(false)
            let msg = data.message;
            let msgtype = 'warning';
            if (data.success === 1){
                msg = data.message;
                msgtype = 'success';
            }
            if (shownotification) {
            Store.addNotification({
              title: title,
              message: msg,
              type: msgtype,
              insert: "top",
              container: "top-center",
              width: 500,
              dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
              }
          });
          }
          }
          )
          .catch(error => {
            console.error('There was an error!', error);
          })
   }

  const confirmcheckdel = (checkedarray, rowdata) => {
      for (let i = 0; i < checkedarray.data.length; i++) {
            var shownotification = false;
            if (i === checkedarray.data.length - 1) {
                shownotification = true;
            }
            var id = rowdata[checkedarray.data[i]['dataIndex']]['id']
            let url = process.env.REACT_APP_CONTRIVE_API_URL+"region/confirm/"+id;
            handleReq(id, 'Deleted!', {}, url, shownotification)
        }
    }

  const fetchData = () => {
    setIsLoading(true)
    const requestOptions = {};
    fetcher(url, requestOptions)
      .then(data => {
        setIsLoading(false)
        setRegions(data)
      })
      .catch(error => {
            console.error('There was an error!', error);
  })
  }
  const columns = [
 {
  name: "code",
  label: "Code",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "name",
  label: "Name",
  options: {
   filter: true,
   sort: true,
  }
 },
];

if (allow_edit){
  columns.push({
  name: "id",
  label: "Edit",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href={"#"} onClick={(e) => handleEdit(value, e)} title='Edit Region'><EditIcon/></a>
          </div>
        )}
 });
  }


  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
      <PageTitle title="Regions View" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : (
          <MUIDataTable
            title="Regions List"
            data={regions.data}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: allow_edit,
              customToolbar: () => {
        return (
          <React.Fragment>
          { allow_edit ? (
          <CustomToolbar />
          ) : null
          }
          </React.Fragment>
        );},
          onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      },
      onRowsDelete: (rowsDeleted, data, newTableData) => {
        var ask = window.confirm("Are you sure you want to delete selected Regions?");
      if (ask) {
        confirmcheckdel(rowsDeleted, regions.data);
        } else {
        return false;
        }
      }
            }}
          />
           )}
        </Grid>
      </Grid>
    </>
  );
}
