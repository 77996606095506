import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import $ from 'jquery'
import {
  Typography,
  Button,
} from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from "react-hook-form";
import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {fetcher} from '../../utils/fetchBData'

const styledeco = {
    display: 'block',
    width: '40%',
    padding: '.375rem .75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
}

function saveContent() {
        var delay_text = document.getElementById("delay").options[document.getElementById("delay").selectedIndex].value; 
        if(delay_text == ''){
            alert("Please select Timeout.");
            return false;
        }
        if (delay_text == "other"){
            if(document.getElementById("otherval").value == ''){
                alert("Please enter other timeout value.");
                return false;
            }
            var delayval_text = document.getElementById("delayval").options[document.getElementById("delayval").selectedIndex].value; 
            if(delayval_text == ''){
                alert("Please select Other timeout value.");
                return false;
            }

        }
        if(document.getElementById("name").value == ''){
            alert("Please enter name.");
            return false;
        }
        document.getElementById('form').submit();
    }


export default function BulkUpdateCustomer() {
   const [resp, setResp] = useState({});
   const [resp_otherval_showhide, setShowHide] = useState({"display": "none"});
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [otherval, setOtherVal] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const { handleSubmit, } = useForm(); 
   const search = useLocation().search;
   let editid = new URLSearchParams(search).get("id");
   if (editid == null){
       editid = ''
   }
   const showDelay = (e) => 
    {  
        //e.preventDefault();
        var delay_text = document.getElementById("delay").options[document.getElementById("delay").selectedIndex].value;
        if (delay_text == "other"){
        //document.getElementById("otherdata").style.display = 'block';
        document.getElementById("otherval").value = '';
        document.getElementById("delayval").selectedIndex = -1;
        setShowHide({"display": "block"});
        }else{
        //document.getElementById("otherdata").style.display = 'none';
        setShowHide({"display": "none"});
        }
        
    }


   const handleReq = (id, title, requestOptions, url) => {
        setIsLoading(true)
          fetcher(url, requestOptions)
            .then(data => {
            setIsLoading(false)
            setResp(data)
            setName(data.name)
            setEmail(data.email)
            setShowHide(data.otherval_showhide)
            setOtherVal(data.otherval)
            let msg = data.message;
            let msgtype = 'warning';
            if (data.success === 1){
                msg = data.message;
                msgtype = 'success';
            }

            Store.addNotification({
              title: title,
              message: msg,
              type: msgtype,
              insert: "top",
              container: "top-center",
              width: 500,
              dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
              }
          });

          }
          )
          .catch(error => {
            console.error('There was an error!', error);
          })
   }

   const cancelFunc = (e) => {
   window.location = "/#/customers"
   }

   const addFunc = (e) => {
        //e.preventDefault();
        var dd_cust = document.getElementById("customer_list");
        var selectedValue = dd_cust.options[dd_cust.selectedIndex].value;
        if (selectedValue == ""){
            alert("Please select Customer.");
            return false;
        }
        var delay_text = document.getElementById("delay").options[document.getElementById("delay").selectedIndex].value; 
        if(delay_text == ''){
            alert("Please select Timeout.");
            return false;
        }
        if (delay_text == "other"){
            if(document.getElementById("otherval").value == ''){
                alert("Please enter other timeout value.");
                return false;
            }
            var delayval_text = document.getElementById("delayval").options[document.getElementById("delayval").selectedIndex].value; 
            if(delayval_text == ''){
                alert("Please select Other timeout value.");
                return false;
            }

        }
        const requestOptions = {
        method: 'POST',
        body: JSON.stringify({'customer_list': $('#customer_list').val(), 'delay':  $('#delay').val(), 'otherval': otherval, 'delayval': $('#delayval').val()}),
        };
        let url = process.env.REACT_APP_CONTRIVE_API_URL+"bulk_customer_update";
        handleReq('', 'Updated!', requestOptions, url);
        window.location = "/#/customers";
       }
 
   const fetchData = (id) => {
    setIsLoading(true)
    //let url = process.env.REACT_APP_CONTRIVE_API_URL+"bulk_customer_update?id="+id;
    let url = process.env.REACT_APP_CONTRIVE_API_URL+"bulk_customer_update";
    fetcher(url, {})
      .then(data => {
        setIsLoading(false)
        setResp(data)
        setName(data.name)
        setEmail(data.email)
        setShowHide(data.otherval_showhide)
        setOtherVal(data.otherval)
      })
      .catch(error => {
            console.error('There was an error!', error);
  })
  }
  useEffect(() => {
       fetchData(editid);
  }, []);
  return (
    <div className="App">
      {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : ( 
      <React.Fragment>
      <Typography variant="h2">Bulk {resp.buttontext} Customer</Typography>
      </React.Fragment>
      )}
        {isLoading ? (
        null //<p><CircularProgress color="inherit" /></p> // no circular progress as coming from abv
      ) : (
      <React.Fragment>
      <form method="POST" id="customerform" name="customerform">

     <label for="customers">Customer's:&nbsp; </label><select multiple="multiple" style={styledeco} size="10" name="customer_list" id="customer_list">
    <option value="">--Select Customer--</option>
    { resp.customers && resp.customers.length ?
    (resp.customers.map((dd) => {
         if (dd['id'] == resp.selectedcus)
             return <option value={dd['id']} selected>{dd['name']}</option>
         else
             return <option value={dd['id']} >{dd['name']}</option>

    })) : null
    })
    </select>
    <br/>

     <label for="timeouts">Timeout:&nbsp; </label><select style={styledeco} name="delay" id="delay" onChange={(e) => showDelay(e)}>
    <option value="">--Select Autodelivery Timeout--</option>
    { resp.delaydropdown && resp.delaydropdown.length ?
    (resp.delaydropdown.map((dd) => {
         if (dd['key'] == resp.delay)
             return <option value={dd['key']} selected>{dd['value']}</option>
         else
             return <option value={dd['key']} >{dd['value']}</option>

    })) : null
    })
    </select>
    <br/>
    <span id="otherdata" style={resp_otherval_showhide} >
        <input type="number" name="otherval" id="otherval"  style={styledeco} value={otherval} placeholder="enter timeout value" onChange={(e) => setOtherVal(e.target.value)}  />
        <select name="delayval" id="delayval" style={styledeco}>
        <option value="">--Select--</option>
        { resp.delayval === "min" ? <option value="min" selected>min</option> : <option value="min">min</option> }
        { resp.delayval === "hour" ? <option value="hour" selected>hour</option> : <option value="hour">hour</option> }
        { resp.delayval === "day" ? <option value="day" selected>day</option> : <option value="day">day</option> }
        </select> </span>
        <br />
        <Button title="Add Customer" onClick={(e) => addFunc(e)}  variant="contained" color="primary">
          {resp.buttontext} 
        </Button>
        &nbsp;&nbsp;<Button title="Cancel" onClick={(e) => cancelFunc(e)} variant="contained" color="primary">
          Cancel
        </Button>
    </form>
    </React.Fragment>
    )}
    </div>
  );
}


