import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import {
  Typography,
  Button,
} from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';
import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {fetcher} from '../../utils/fetchBData'

const styledeco = {
    display: 'block',
    width: '20%',
    padding: '.375rem .75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
}

export default function LogsReplay() {
   const [resp, setResp] = useState({});
   const [customername, setCustomerName] = useState("");
   const [subscription, setSubscription] = useState("");
   const [blob_prefix, setBlobPrefix] = useState("");
   const [rtcetag, setRtceTag] = useState("");
   const [ip, setIp] = useState("");
   const [ip_cnetwork, setIpCnetwork] = useState("");
   const [srcip, setSrcIp] = useState("");
   const [destip, setDestIp] = useState("");
   const [dest_ip_cnetwork, setDestIpCnetwork] = useState("");
   const [append_config, setAppendConfig] = useState("");
   const [datestart, setDateStart] = useState("");
   const [dateend, setDateEnd] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const search = useLocation().search;
   let editid = new URLSearchParams(search).get("id");
   if (editid == null){
       editid = ''
   }
   const handleReq = (id, title, requestOptions, url) => {
        setIsLoading(true)
          fetcher(url, requestOptions)
            .then(data => {
            setIsLoading(false)
            setResp(data)
            let msg = data.message;
            let msgtype = 'warning';
            if (data.success === 1){
                msg = data.message;
                msgtype = 'success';
            }

            Store.addNotification({
              title: title,
              message: msg,
              type: msgtype,
              insert: "top",
              container: "top-center",
              width: 500,
              dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
              }
          });

          }
          )
          .catch(error => {
            console.error('There was an error!', error);
          })
   }

   const addFunc = (e) => {
        //e.preventDefault();
        if(document.getElementById("code").value == ''){
            alert("Please enter code.");
            return false;
        }
        if(document.getElementById("name").value == ''){
            alert("Please enter name.");
            return false;
        }
        const pdata = new FormData();
        pdata.append("id", editid);
        pdata.append("name", ip);
        pdata.append("code", destip);
        const requestOptions = {
        method: 'POST',
        body: pdata,
        };
        let url = process.env.REACT_APP_CONTRIVE_API_URL+"add_region";
        handleReq('', 'Added!', requestOptions, url);
        window.location = "/#/logsreplay";
       }
 
   const fetchData = (id) => {
    setIsLoading(true)
    let url = process.env.REACT_APP_CONTRIVE_API_URL+"logs_replay?id="+id;
    fetcher(url, {})
      .then(data => {
        setIsLoading(false)
        setResp(data)
      })
      .catch(error => {
            console.error('There was an error!', error);
  })
  }
  useEffect(() => {
       fetchData(editid);
  }, []);
  return (
    <div className="App">
      {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : ( 
      <React.Fragment>
      <Typography variant="h2">Logs Replay</Typography>
      </React.Fragment>
      )}
        {isLoading ? (
        null //<p><CircularProgress color="inherit" /></p> // no circular progress as coming from abv
      ) : (
      <React.Fragment>
      <form method="POST" id="form" name="form">

     <div>Customer: <input type="text" style={styledeco} id="customername" name="customername" value={customername} onChange={e => setCustomerName(e.target.value)} placeholder="Customer id"/></div>
     <div>Subscription: <input type="text" style={styledeco} id="subscription" name="subscription" value={subscription} onChange={e => setSubscription(e.target.value)} placeholder="Subscription name" /></div>
     <div>Blob Prefix: <input type="text" style={styledeco} id="blobprefix" name="blobprefix" value={blob_prefix} onChange={e => setBlobPrefix(e.target.value)} placeholder="Blob Prefix" /></div>
     <div>Rtce Tag: <input type="text" style={styledeco} id="rtcetag" name="rtcetag" value={rtcetag} onChange={e => setRtceTag(e.target.value)} placeholder="latest" /></div>
     <div>Ip: <input type="text" style={styledeco} id="ip" name="ip" value={ip} onChange={e => setIp(e.target.value)} placeholder="x.x.x.x for src/dest filtering" /></div>
     <div>Ip Cnetwork: <input type="text" style={styledeco} id="ip_cnetwork" name="ip_cnetwork" value={ip_cnetwork} onChange={e => setIpCnetwork(e.target.value)} placeholder="x.x.x.x" /></div>
     <div>Src Ip: <input type="text" style={styledeco} id="srcip" name="srcip" value={srcip} onChange={e => setSrcIp(e.target.value)} placeholder="x.x.x.x" /></div>
     <div>Dest Ip: <input type="text" style={styledeco} id="destip" name="destip" value={destip} onChange={e => setDestIp(e.target.value)} placeholder="x.x.x.x" /></div>
     <div>Dest Ip Cnetwork: <input type="text" style={styledeco} id="dest_ip_cnetwork" name="dest_ip_cnetwork" value={dest_ip_cnetwork} onChange={e => setDestIpCnetwork(e.target.value)} placeholder="x.x.x.x" /></div>
     <div>Append Config: <textarea id="appendconfig" style={styledeco} name="appendconfig" rows="3" cols="80" value={append_config} onChange={e => setAppendConfig(e.target.value)} placeholder="foo1=bar1&#10;foo2=bar2&#10;" /></div>
     <div>Date Start (In UTC): <input type="text" style={styledeco} id="datestart" name="datestart" value={datestart} onChange={e => setDateStart(e.target.value)} placeholder="YYYY-mm-dd hh:mm" /></div>
     <div>Date End (In UTC): <input type="text" style={styledeco} id="dateend" name="dateend" value={dateend} onChange={e => setDateEnd(e.target.value)} placeholder="YYYY-mm-dd hh:mm" /></div>
     <div>Send Alert: <label for="True">True</label> <input type="radio" name="send_alert" id="send_alert_true" value="true" checked />
     <label for="False">False</label>
     <input type="radio" name="send_alert" id="send_alert_false" value="false" /></div>
      <br />
        <Button title="Add" onClick={(e) => addFunc(e)}  variant="contained" color="primary">
          {resp.buttontext} 
        </Button>
    </form>
    </React.Fragment>
    )}
    </div>
  );
}


