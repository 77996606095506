import { tokenRequest } from './msalConfig'
import { msalInstance } from '../components/Layout/Layout'
import {get_access_txt} from './Utils';

/**function get_apara(url, accounts){
  var platform_acc_txt = get_access_txt(accounts, 'Platform');
  var region_acc_txt = get_access_txt(accounts, 'Region');
  var customer_acc_txt = get_access_txt(accounts, 'Customer');
  var add_param = url+"?platform="+platform_acc_txt+"&region="+region_acc_txt+"&customer="+customer_acc_txt;
  return add_param;
  }
**/

export const createHeaders = async () => {
    if (typeof(msalInstance.getActiveAccount()) != "undefined"){
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }
    const response = await msalInstance.acquireTokenSilent(tokenRequest)
    return {
        "Authorization": `Bearer ${response.accessToken}`,
        "Content-Type": "application/json",
    }
}


export const fetcher = async (url: string, requestOptions: object) => {
    if (!requestOptions.hasOwnProperty('headers')){
        requestOptions.headers = await createHeaders()
    }
    return fetch(url, requestOptions).then((res) => res.json())
}

