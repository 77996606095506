import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import $ from 'jquery'
import { Editor } from "@tinymce/tinymce-react";
import {
  Typography,
  Button,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from "react-hook-form";
import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {fetcher} from '../../utils/fetchBData'
import { makeStyles } from '@mui/styles';
// msal auth
import { useMsal} from "@azure/msal-react"
import {get_access_txt, check_read_only} from '../../utils/Utils';

const options={
    //filter: true,
    filterType: 'checkbox',
    responsive: 'stacked',
    selectableRows: false 
    }

const useStyles = makeStyles({
  leftText: {
    textAlign: "left"
  },
  rightText: {
    textAlign: "right"
  }
});

export default function EditInc() {
   const [resp, setResp] = useState({});
   const [inccon, setIncContent] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const { handleSubmit, } = useForm();
   const { accounts, instance } = useMsal();
   var read_only_flag = check_read_only(accounts);
   const onDiscard = (discarddata: any) => discardFunc(discarddata);

   const editBouncerFunc = (id, e) => {
      if (id) {
          $('#saverev').val("0");
          $('#reloadpopup').val("1");
          window.open("/#/editbouncer?id="+id,'bouncerwin','width=800,height=800');

   }
   }

   const handleReq = (id, title, requestOptions, url, shownotification=true) => {

        setIsLoading(true)
          fetcher(url, requestOptions)
            .then(data => {
            setIsLoading(false)
            setResp(data)
            setIncContent(data.content)
            let msg = data.message;
            let msgtype = 'warning';
            if (data.success === 1){
                msg = data.message;
                msgtype = 'success';
            }
            if (shownotification) {
            Store.addNotification({
              title: title,
              message: msg,
              type: msgtype,
              insert: "top",
              container: "top-center",
              width: 500,
              dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
              }
          });
          }
          }
          )
          .catch(error => {
            console.error('There was an error in handle req!', error);
          })
   }

   const confirmchkdelFunc = (editid, revid, revno, e) => {
       e.preventDefault();
       var ask = window.confirm("Are you sure you want to delete "+revno+" revision?");
       if (ask) {
           $('#revid').val(revid);
           $('#revno').val(revno);
           $('#delrevision').val("1");
           const requestOptions = {
           method: 'POST',
           body: JSON.stringify({'revid': $('#revid').val(), 'revno': $('#revno').val(), 'delrevision': $('#delrevision').val()}),
           };
           let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/edit/"+editid;
           handleReq(editid, 'Revision!', requestOptions, url);
           //window.location = "/#/app/editinc?id="+editid;
           //return false;
      }else{
          return false;

      }
   }

   const editRevisionFunc = (editid, revid, revno, e) => {
       e.preventDefault();
       $('#revid').val(revid);
       $('#revno').val(revno);
       $('#editrevision').val("1");
       const requestOptions = {
       method: 'POST',
       body: JSON.stringify({'revid': $('#revid').val(), 'revno': $('#revno').val(), 'editrevision': $('#editrevision').val()}),
       };
       let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/edit/"+editid;
       handleReq(editid, 'Revision!', requestOptions, url, false);
   }

   const saveRevisionFunc = (updatedata, e) => {
       $('#saverev').val("1");
       const requestOptions = {
       method: 'POST',
       body: JSON.stringify({'saverev': $('#saverev').val(), 'inccontent': inccon}),
       };
       let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/edit/"+updatedata;
       handleReq(updatedata, 'Revision!', requestOptions, url);
   }

   const updateFunc = (updatedata, e) => {
        //e.preventDefault();
        var ed = window.tinymce.activeEditor;
        $('#hidContents').val(ed.getContent({'format': 'raw'}));
        $('#savecontent').val("1");
        const requestOptions = {
        method: 'POST',
        body: JSON.stringify({'savecontent': $('#savecontent').val(), 'revid': $('#revid').val(), 'revno': $('#revno').val(), 'def': resp.def,  'inccontent': inccon}),
        };
        let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/edit/"+updatedata;
        handleReq(updatedata, 'Updated!', requestOptions, url);
       }
 
   const onViewpdfFunc = (data, e) => {
     e.preventDefault();
     var revidval = $('#revid').val();
     if(revidval){
         window.open("/#/viewpdf?pdf="+revidval+"&loc=revision", "_blank");
     }else{
         window.open("/#/viewpdf?pdf="+data+"&loc=queue", "_blank");
     }
   }
 
   const sendtocustomerFunc = (id, email, cust, revid, e) => {
            e.preventDefault();
            let url = '';
            var ask = window.confirm("Are you sure you want to send this Incident to "+cust+" customer?\nEmail Notification will be sent to: "+email);
            if (ask) {
              if (revid){
                  url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/confirm/"+revid+"/revision";
              }else{
                  url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/confirm/"+id+"/queue";
              }
            const requestOptions = {
            };
            handleReq(id, 'Sent!', requestOptions, url);
           }
            else {
           return false;
           }
   window.location.href = "/#/qincidents";
   }
 
   const cancelFunc = (canceldata) => {
       let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/cancel/"+canceldata;
       const requestOptions = {
       };
       handleReq(canceldata, 'Cancel', requestOptions, url, false);
       window.location.href = "/#/qincidents";
   }

   const discardFunc = (discarddata)=> event => {
       //event.preventDefault();
       var ask = window.confirm("Are you sure you want to discard this Incident?");
      if (ask) {
          let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/discard/"+discarddata;
          const requestOptions = {};
          handleReq(discarddata, 'Discarded!', requestOptions, url);
          window.location.href = "/#/qincidents";
      }else {
      return false;
      }
   }

   const columns = [
 {
  name: "revision_no",
  label: "Revision No.",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "created_at",
  label: "Created At",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "activity_at",
  label: "Last Activity At",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "idhash",
  label: "Edit",
  options: {
   filter: false,
   sort: false,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href='#' onClick={(e) => editRevisionFunc(editid, tableMeta.rowData[4], tableMeta.rowData[0], e)} title={"Edit Revision:"+value} ><EditIcon/></a>
          </div>
        )
  }
 },
 {
  name: "id",
  label: "View Pdf",
  options: {
   filter: false,
   sort: false,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href={"/#/viewpdf?pdf="+value+"&loc=revision"} title={"Original Report:"+value} target="_blank" rel="noreferrer"><PictureAsPdfIcon/></a>
          </div>
        )
  }
 },
 {
  name: "idhash",
  label: "Delete",
  options: {
   filter: false,
   sort: false,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href='#' onClick={(e) => confirmchkdelFunc(editid, tableMeta.rowData[4], tableMeta.rowData[0], e)} title={"Delete Report:"+value}><DeleteIcon/></a>
          </div>
        )
  }
 }
]

   const viewDefaultReportFunc = (id, e) => {
       e.preventDefault();
       //window.location = "/#/editinc?id="+id+"&def=1"
       //window.location.reload(false);
       $('#default').val("1");
       const pdata = new FormData();
       pdata.append("def", "1");
       const requestOptions = {
       method: 'POST',
       body: JSON.stringify({'def': "1"}),
       };
       let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/edit/"+id;
       handleReq(editid, 'Default!', requestOptions, url, false);
   }
   const handleEditorChange = (content, editor) => {
     setIncContent(content);
   }
   const classes = useStyles();
   const search = useLocation().search;
   const editid = new URLSearchParams(search).get("id");
   const defaultval = new URLSearchParams(search).get("def");
   const unsignedUploadPreset = 'adz8s31b';  
   const fetchData = (id) => {
    setIsLoading(true)
    let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/edit/"+id;
    const requestOptions = {};
    fetcher(url, requestOptions)
      .then(data => {
        setIsLoading(false)
        setResp(data)
        setIncContent(data.content)
      })
      .catch(error => {
            console.error('There was an error in fetch data for edit!', error);
  })
  }
  useEffect(() => {
       fetchData(editid);
  }, []);
  return (
    <div className="App">
      {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : ( 
     resp.revno ? (
      <React.Fragment>
      <Typography variant="h2">Edit Incident - {resp.revno}</Typography>
      <Typography variant="h7">Alert Triggered by: {resp.content_producer_id}</Typography>
      &nbsp;<a href = '#' onClick={(e) => viewDefaultReportFunc(editid, e)}> View default Report </a>
      </React.Fragment>
      ):(
      <React.Fragment>
      <Typography variant="h2">Edit Incident</Typography>
      <Typography variant="h7">Alert Triggered by: {resp.content_producer_id}</Typography>
      </React.Fragment>
      ))}
        {isLoading ? (
        null //<p><CircularProgress color="inherit" /></p> // no circular progress as coming from abv
      ) : (
      <React.Fragment>
      <form method="POST" id="editform" name="editform">
      <Editor
        apiKey="qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"
        initialValue={resp.content}
        onEditorChange={handleEditorChange}
        init={{
        height: 600,
        toolbar: "code insertfile undo redo image | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link imageupload",
        plugins: "advlist autolink lists link image charmap print anchor searchreplace visualblocks code fullscreen code insertdatetime media table contextmenu paste",
        init_instance_callback : function() {
        var freeTiny = document.querySelector('.tox .tox-notification--in');
        freeTiny.style.display = 'none';
        //var freeTiny1 = document.querySelector('.tox-notification .tox-notification--in .tox-notification--warning');
        //freeTiny1.style.display = 'none';
        },
        forced_root_block:"",
        images_upload_credentials: true,
        image_title: true,
        automatic_uploads: true,
        file_picker_types: 'image',
        file_picker_callback: function (cb, value, meta) {
              var input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');
              var url = process.env.REACT_APP_CONTRIVE_API_URL+"image_uploader";
              var xhr = new XMLHttpRequest();
              var fd = new FormData();
              xhr.open('POST', url, true);

              input.onchange = function () {
                var file = this.files[0];
                var reader = new FileReader();
                xhr.onload = function () {
                  if (xhr.status === 200) {
                    // File uploaded successfully
                    var response = JSON.parse(xhr.responseText);
                    var url = response.location;
                    cb(url, { title: 'tmp.png' });

                  }
                };

                reader.onload = function () {
                  var id = 'blobid' + (new Date()).getTime();
                  var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                  var base64 = reader.result.split(',')[1];

                  var blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);
                  // call the callback and populate the Title field with the file name
                  fd.append('upload_preset', unsignedUploadPreset);
                  fd.append('tags', 'browser_upload');
                  fd.append('file', blobInfo.blob(), blobInfo.filename());

                  xhr.send(fd);

                };

                reader.readAsDataURL(file);
              };

              input.click();
            },
            images_upload_handler: (blobInfo, success, failure) => {
              let data = new FormData();
              var reader = new FileReader();
              var url = process.env.REACT_APP_CONTRIVE_API_URL+"image_uploader";
              data.append('file', blobInfo.blob(), blobInfo.filename());
              data.append('upload_preset', unsignedUploadPreset);
              data.append('tags', 'browser_upload');
              axios.post(url, data, { headers: { 'Content-Type': 'multipart/form-data'}
                 })
                .then(function (res) {
                  //success(res.data.location)
                })
                .catch(function (err) {
                  //console.log(err)
                });
              reader.readAsDataURL(blobInfo.blob())
            }
      }}
      />
      <input id="hidContents" type="hidden" name="hidContents" />
    <input id="saverev" type="hidden" name="saverev" />
    <input id="default" type="hidden" name="default" />
    <input id="savecontent" type="hidden" name="savecontent" />
    <input id="delrevision" type="hidden" name="delrevision" />
    <input id="editrevision" type="hidden" name="editrevision" />
    <input id="editrevisionsubmit" type="hidden" name="editrevisionsubmit" />
    <input id="revno" type="hidden" name="revno" value={resp.revno} />
    <input id="revid" type="hidden" name="revid" value={resp.revid} />
    <input id="reloadpopup" type="hidden" name="reloadpopup" />
      <br />
        <Button title="Cancel Incident i.e. back to list page, will not mark as edit mode" onClick={(e) => cancelFunc(editid, e)} variant="contained" color="primary">
          Cancel
        </Button>
        {read_only_flag == true ? (null)  : (
        <React.Fragment>
        &nbsp;&nbsp;<Button title="Update Incident Template" onClick={(e) => updateFunc(editid, e)}  variant="contained" color="primary">
          Update
        </Button>
        &nbsp;&nbsp;<Button title="Discard Incident i.e. seems not good for customer" onClick={handleSubmit(onDiscard(editid))} variant="contained" color="primary">
          Discard
        </Button>
        &nbsp;&nbsp;<Button title="Edit bouncer content" onClick={(e) => editBouncerFunc(editid, e)} variant="contained" color="primary">
          Edit Bouncer Content
        </Button>
        &nbsp;&nbsp;<Button title="View Pdf" onClick={(e) => onViewpdfFunc(editid, e)} variant="contained" color="primary">
          View PDF
        </Button>
        &nbsp;&nbsp;<Button title="Send incident report to customer" onClick={(e) => sendtocustomerFunc(editid, resp.cmdb_email, resp.cmdb_customer, resp.revid,  e)} variant="contained" color="primary">
          Send to Customer
        </Button>
        &nbsp;&nbsp;<Button title="Replay Logs" variant="contained" color="primary">
          Replay Logs
        </Button>
        </React.Fragment>
        )}
    </form>
    </React.Fragment>
    )}
    <br/>
    <br/>
    <Grid container spacing={4}>
        <Grid item xs={12}>
          {isLoading ? (
      null ) : (
           read_only_flag == false && resp.revisions && resp.revisions.length ? (
          <MUIDataTable
            title="Incident Revisions"
            data={resp.revisions}
            columns={columns}
            options={options}
          />
          ) : (null)
          )}
        </Grid>
      </Grid>
    </div>
  );
}


