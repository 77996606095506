export const get_access_txt = (accounts, type='Platform') => {
        var access_txt = '';
        try {
            var tmp_txt = "";
            var roles = accounts[0]?.idTokenClaims.roles;
            for (let i=0; i < roles.length; i++) {
                let roles_coming = roles[i]
                if (roles_coming.includes("#")){
                    roles_coming = roles_coming.split("#")
                    roles_coming = roles_coming[1]
                }
                let role_split = roles_coming.split("@")
                if (type.toLowerCase() === role_split[0].toLowerCase())
                    tmp_txt = tmp_txt+role_split[1]+","
            }
            return tmp_txt.substring(0, tmp_txt.length - 1);;
        }catch (error) {
            console.error(error);
            return access_txt;
        }
    }


export const check_read_only = (accounts, check_value="READ_ONLY") => {
        try {
            var roles = accounts[0]?.idTokenClaims.roles;
            return roles.includes(check_value);
        }catch (error) {
            console.error(error);
            return false;
        }
    }
