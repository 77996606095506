import React, {useEffect, useState } from "react";
import {
  Grid,
  Button
} from "@material-ui/core";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
} from "recharts";
import CircularProgress from '@mui/material/CircularProgress';

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import ApexLineChartSentInc from "./components/ApexLineChartSentInc";
import ApexLineChartDiscardInc from "./components/ApexLineChartDiscardInc";
import ApexLineChartSentIncSig from "./components/ApexLineChartSentIncSig";
import ApexLineChartDiscardIncSig from "./components/ApexLineChartDiscardIncSig";

import { Link } from 'react-router-dom';

import {fetcher} from '../../utils/fetchBData'
import { useMsal } from "@azure/msal-react";

export default function Dashboard(props) {
  var classes = useStyles();

  const [stats, setStats] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const { accounts } = useMsal();
  let url = process.env.REACT_APP_CONTRIVE_API_URL+"stats";
  const fetchData = () => {
    setIsLoading(true);
    const requestOptions = {};
    let data = fetcher(url, requestOptions).then(data => {
        setStats(data)
        setIsLoading(false)
      })
      .catch(error => {
            console.error('There was an error!', error);
  });
  }
  useEffect(() => {
    fetchData()
  }, 0)

  return (
    <>
      <PageTitle title="Dashboard" button={<Button
      variant="contained"
      size="medium"
      color="secondary"
      component={Link}
      to="/qincidents"
    >
        Latest Incidents 
    </Button>} />
      <Grid container spacing={4}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget
            title="Queued Incident's"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
              <Typography size="xxl" weight="medium" noWrap>
               {isLoading ? (
                 <p><CircularProgress color="inherit" /></p>
                  ) : (
                 <span>
                 {stats.incidentscount}
                 </span>
               )}  
              </Typography>
                </Grid>
              </Grid>
            </div>
          </Widget>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget title="Platform Stats" upperTitle className={classes.card}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ResponsiveContainer width="100%" height={144}>
                  {isLoading ? (
                 <p><CircularProgress color="inherit" /></p>
                  ) : (
                  <PieChart>
                    <Pie
                      data={stats.platform_stats}
                      innerRadius={30}
                      outerRadius={40}
                      dataKey="value"
                    >
                      {stats.platform_stats.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={entry.color}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                  )}
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.pieChartLegendWrapper}>
                  {isLoading ? (
                 ''
                  ) : (
                  stats.platform_stats.map(({ name, value, color }, index) => (
                    <div key={color} className={classes.legendItemContainer}>
                      <Dot color={color} />
                      <Typography style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                        &nbsp;{name}&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{value}
                      </Typography>
                    </div>
                  ))
                  )}
                </div>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget title="Region Stats" upperTitle className={classes.card}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ResponsiveContainer width="100%" height={144}>
                  {isLoading ? (
                 <p><CircularProgress color="inherit" /></p>
                  ) : (
                  <PieChart>
                    <Pie
                      data={stats.region_stats}
                      innerRadius={30}
                      outerRadius={40}
                      dataKey="value"
                    >
                      {stats.region_stats.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={entry.color}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                  )}
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.pieChartLegendWrapper}>
                  {isLoading ? (
                 ''
                  ) : (
                  stats.region_stats.map(({ name, value, color }, index) => (
                    <div key={color} className={classes.legendItemContainer}>
                      <Dot color={color} />
                      <Typography style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                        &nbsp;{name}&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{value}
                      </Typography>
                    </div>
                  ))
                  )}
                </div>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget title="Customer Stats" upperTitle className={classes.card}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ResponsiveContainer width="100%" height={144}>
                  {isLoading ? (
                 <p><CircularProgress color="inherit" /></p>
                  ) : (
                  <PieChart>
                    <Pie
                      data={stats.customer_stats}
                      innerRadius={30}
                      outerRadius={40}
                      dataKey="value"
                    >
                      {stats.customer_stats.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={entry.color}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                  )}
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.pieChartLegendWrapper}>
                  {isLoading ? (
                 ''
                  ) : (
                  stats.customer_stats.map(({ name, value, color }, index) => (
                    <div key={color} className={classes.legendItemContainer}>
                      <Dot color={color}  />
                      <Typography style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                        &nbsp;{name}&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{value}
                      </Typography>
                    </div>
                  ))
                  )}
                </div>
              </Grid>
            </Grid>
          </Widget>
        </Grid> 
        <Grid item xs={12} md={12}>
          <Widget title="Sent Inc per Day" noBodyPadding upperTitle>
          <ApexLineChartSentInc />
          </Widget>
        </Grid>
        <Grid item xs={12} md={12}>
          <Widget title="Discard Inc per Day" noBodyPadding upperTitle>
          <ApexLineChartDiscardInc />
          </Widget>
        </Grid>
        <Grid item xs={12} md={12}>
          <Widget title="Sent Signature per Day" noBodyPadding upperTitle>
          <ApexLineChartSentIncSig />
          </Widget>
        </Grid>
        <Grid item xs={12} md={12}>
          <Widget title="Discard Signature per Day" noBodyPadding upperTitle>
          <ApexLineChartDiscardIncSig />
          </Widget>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget title="Severity Stats" upperTitle className={classes.card}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ResponsiveContainer width="100%" height={144}>
                  {isLoading ? (
                 <p><CircularProgress color="inherit" /></p>
                  ) : (
                  <PieChart>
                    <Pie
                      data={stats.severity_stats}
                      innerRadius={30}
                      outerRadius={40}
                      dataKey="value"
                    >
                      {stats.severity_stats.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={entry.color}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                  )}
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.pieChartLegendWrapper}>
                  {isLoading ? (
                 ''
                  ) : (
                  stats.severity_stats.map(({ name, value, color }, index) => (
                    <div key={color} className={classes.legendItemContainer}>
                      <Dot color={color}  />
                      <Typography style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                        &nbsp;{name}&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{value}
                      </Typography>
                    </div>
                  ))
                  )}
                </div>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={9} md={4} sm={6} xs={12}>
          <Widget title="Signature Stats" upperTitle className={classes.card}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ResponsiveContainer width="100%" height={144}>
                  {isLoading ? (
                 <p><CircularProgress color="inherit" /></p>
                  ) : (
                  <PieChart>
                    <Pie
                      data={stats.signature_stats}
                      innerRadius={30}
                      outerRadius={40}
                      dataKey="value"
                    >
                      {stats.signature_stats.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={entry.color}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                  )}
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.pieChartLegendWrapper}>
                  {isLoading ? (
                 ''
                  ) : (
                  stats.signature_stats.map(({ name, value, color }, index) => (
                    <div key={color} className={classes.legendItemContainer}>
                      <Dot color={color}  />
                      <Typography style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                        &nbsp;{name}&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{value}
                      </Typography>
                    </div>
                  ))
                  )}
                </div>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
