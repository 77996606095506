import React , { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import { Store } from 'react-notifications-component'
import dateFormat from "dateformat";

// components
import PageTitle from "../../components/PageTitle";

import { useMsal } from "@azure/msal-react";
import {fetcher } from '../../utils/fetchBData'
import {get_access_txt} from '../../utils/Utils';

import {
  PictureAsPdf as PictureAsPdfIcon,
} from "@material-ui/icons";


function get_revision(value, idval){
    var val =  value.split("/").pop();
    var revval = val.replace(idval+"_", "").replace(".html", "").replace(idval, "");
    if (revval == '') {
        revval = 'Default'
    }
    return revval;
    }

const columns = [
 {
  name: "idhash",
  label: "Id",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href={"/#/viewpdf?pdf="+value+"&loc=sent"} title={value} target="_blank" rel="noreferrer">{value.substring(0, 4)+"..."}</a>
          </div>
        )
  }
 },
 {
  name: "signature",
  label: "EventName",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "customer",
  label: "Customer",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "severity",
  label: "Severity",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "report_ref",
  label: "Report Revision",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
          {get_revision(value, tableMeta.rowData[0])}               
          </div>
        )
  }
 },
 {
  name: "trigger_at",
  label: "TriggerDate",
  options: {
   filter: true,
   sort: false,
  }
 },
 {
  name: "activity_at_ts",
  label: "Sent Date",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <p>
            {dateFormat(value*1000, "yyyy-mm-dd hh:mm:ss")}
          </p>
        )
  }
 },
 {
  name: "idhash",
  label: "View Pdf",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href={"/#/viewpdf?pdf="+value+"&loc=sent"} target="_blank" rel="noreferrer"><PictureAsPdfIcon /></a>
          </div>
        )
          }
 }
];

export default function Tables() {
  const [incs, setInc] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { accounts } = useMsal();
  const fetchData = () => {
    setIsLoading(true)
    let url = process.env.REACT_APP_CONTRIVE_API_URL+"sentincidents?incmode=auto";
    let data = fetcher(url, {}).then(data => {
    setInc(data)
    setIsLoading(false)
  })
  }
  const handleReq = (id, title, requestOptions, url, shownotification=true) => {

        setIsLoading(true)
          fetcher(url, requestOptions)
            .then(data => {
            setInc(data)
            setIsLoading(false)
            let msg = data.message;
            let msgtype = 'warning';
            if (data.success === 1){
                msg = data.message;
                msgtype = 'success';
            }
            if (shownotification) {
            Store.addNotification({
              title: title,
              message: msg,
              type: msgtype,
              insert: "top",
              container: "top-center",
              width: 500,
              dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
              }
          });
          }
          }
          )
          .catch(error => {
            console.error('There was an error!', error);
          })
   }

    const confirmcheckdel = (checkedarray, rowdata) => {
      for (let i = 0; i < checkedarray.data.length; i++) {
            var shownotification = false;
            if (i === checkedarray.data.length - 1) {
                shownotification = true;
            }
            var id = rowdata[checkedarray.data[i]['dataIndex']]['idhash']
            let url = process.env.REACT_APP_CONTRIVE_API_URL+"sentincidents/confirm/"+id+"?incmode=auto";
            handleReq(id, 'Deleted!', {}, url, shownotification)
        }
    }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
      <PageTitle title="Sent (Auto)Incidents View" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
           {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : ( 
          <MUIDataTable
            title="Sent (Auto)Incident List"
            data={incs.incidents}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: false,
              onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      },
      onRowsDelete: (rowsDeleted, data, newTableData) => {
        var ask = window.confirm("Are you sure you want to delete selected Incidents?");
      if (ask) {
        confirmcheckdel(rowsDeleted, incs.incidents);
        } else {
        return false;
        }
      }
            }}
          />
          )}
        </Grid>
      </Grid>
    </>
  );
}
