import React , { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import { red } from '@mui/material/colors';
import { Store } from 'react-notifications-component'
// components
import PageTitle from "../../components/PageTitle";
//import CustomToolbarSelect from "./CustomToolbarSelect";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import {fetcher} from '../../utils/fetchBData'

// msal auth
import { useMsal} from "@azure/msal-react"
import {get_access_txt, check_read_only} from '../../utils/Utils';

import {
  PictureAsPdf as PictureAsPdfIcon,
} from "@material-ui/icons";

function handleEdit(id, e) {
    e.preventDefault();
    window.location = "/#/editinc?id="+id;
  }

function handleHash(id, e) {
    e.preventDefault();
    return false;
  }

const classes = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

const columns = [
 {
  name: "idhash",
  label: "Id",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href='#' onClick={(e) => handleEdit(value, e)}  title={value}>{value.substring(0, 4)+"..."}</a>
          </div>
        )
  }
 },
 {
  name: "signature",
  label: "EventName",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "customer",
  label: "Customer",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "severity",
  label: "Severity",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "alert_ref",
  label: "Sandbox",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
          {value.split("/")[5].split("=")[1]} 
          </div>
        )
  }
 },
 {
  name: "src_ip",
  label: "Source Ip",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {(tableMeta.rowData[5] != "") ?
            value 
            : 'N/A'}
          </div>
        )
  }
 },
 {
  name: "dest_ip",
  label: "Dest Ip",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {(tableMeta.rowData[6] != "") ?
            value 
            : 'N/A'}
          </div>
        )
  }
 },
 {
  name: "trigger_at",
  label: "TriggerDate",
  options: {
   filter: true,
   sort: false,
  }
 },
{
  name: "idhash",
  label: "View Default Pdf",
  options: {
   filter: false,
   sort: false,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href={"/#/viewpdf?pdf="+value+"&loc=queue"} title={"Original Report:"+value} target="_blank" rel="noreferrer"><PictureAsPdfIcon color="green"/></a>
          </div>
        )
                }
 },
{
  name: "rev_exist",
  label: "View Modified Pdf",
  options: {
   filter: false,
   sort: false,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value ?
            <a href={"/#/viewpdf?pdf="+tableMeta.rowData[0]+"&loc=revision"} title={"Modified Report:"+tableMeta.rowData[0]} target="_blank" rel="noreferrer"><PictureAsPdfIcon color="secondary"/></a>
            : <a href='#' onClick={(e) => handleHash(value, e)}   title={"No Report revision exist for:"+tableMeta.rowData[0]} target="_blank" rel="noreferrer"><PictureAsPdfIcon color="disabled"/></a>}
          </div>
        )
                }
 }
];

export default function Tables() {
  const [incs, setInc] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { accounts, instance } = useMsal();
  var read_only_flag = check_read_only(accounts);
  var allow_edit = read_only_flag ? false : true;
  const confirmcheckdel = (checkedarray, rowdata) => {
      for (let i = 0; i < checkedarray.data.length; i++) {
            var shownotification = false;
            if (i === checkedarray.data.length - 1) {
                shownotification = true;
            }
            var id = rowdata[checkedarray.data[i]['dataIndex']]['idhash']
            let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/discard/"+id;
            handleReq(id, 'Discarded!', {}, url, shownotification)
        }
    }

  const handleDiscard = (selectedRows, rowdata) => {
       var ask = window.confirm("Are you sure you want to discard selected Incidents?");
      if (ask) {
          var checkedarray = selectedRows;
          for (let i = 0; i < checkedarray.data.length; i++) {
              var shownotification = false;
              if (i === checkedarray.data.length - 1) {
                  shownotification = true;
              }
              var id = rowdata[checkedarray.data[i]['dataIndex']]['idhash'];
              let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/discard/"+id;
              handleReq(id, 'Discarded!', {}, url, shownotification)
          }
      } else {
        return false;
      }
    };
  const handleReq = (id, title, requestOptions, url, shownotification=true) => {

        setIsLoading(true)
          fetcher(url, requestOptions)
            .then(data => {
            setInc(data)
            setIsLoading(false)
            let msg = data.message;
            let msgtype = 'warning';
            if (data.success === 1){
                msg = data.message;
                msgtype = 'success';
            }
            if (shownotification) {
            Store.addNotification({
              title: title,
              message: msg,
              type: msgtype,
              insert: "top",
              container: "top-center",
              width: 500,
              dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
              }
          });
          }
          }
          )
          .catch(error => {
            console.error('There was an error!', error);
          })
   }
  const options={
    //filter: true,
    filterType: 'checkbox',
    responsive: 'stacked',
    selectableRows: allow_edit,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <div className={classes.iconContainer}>
        <Tooltip title={"Discard Incidents"}>
          <IconButton className={classes.iconButton} onClick={(e) =>handleDiscard(selectedRows, incs.incidents)}>
            <DriveFileMoveIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </div>
    ),
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      },
      onRowsDelete: (rowsDeleted, data, newTableData) => {
        var ask = window.confirm("Are you sure you want to discard selected Incidents?");
      if (ask) {
        confirmcheckdel(rowsDeleted, incs.incidents);
        } else {
        return false;
        }
      }
    }
    const fetchData = () => {
    setIsLoading(true)
    let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents";
    const requestOptions = {};
    let data = fetcher(url, requestOptions).then(data => {
      setInc(data)
      setIsLoading(false)
    });
    }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
      <PageTitle title="Queued Incidents View" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : (
          <MUIDataTable
            title="Queued Incident List"
            data={incs.incidents}
            columns={columns}
            options={options}
          />
          )}
        </Grid>
      </Grid>
    </>
  );
}
