import React , { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';

// components
import PageTitle from "../../components/PageTitle";
import {fetcher} from '../../utils/fetchBData'

const columns = [
 {
  name: "platform_name",
  label: "Platform",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "region_name",
  label: "Region",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "region_code",
  label: "Region Code",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "faere_heartbeat_dt",
  label: "Faere Heartbeat",
  options: {
   filter: true,
   sort: true,
  }
 }
];

export default function Tables() {
  const [statuses, setFStatus] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  let url = process.env.REACT_APP_CONTRIVE_API_URL+"faere";
  const fetchData = () => {
    setIsLoading(true)
    fetcher(url, {})
      .then(data => {
        setIsLoading(false)
        setFStatus(data.data)
      })
      .catch(error => {
            console.error('There was an error!', error);
  })
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
      <PageTitle title="Faere Status View" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : (
          <MUIDataTable
            title="Faere Status List"
            data={statuses}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: false,
            }}
          />
           )}
        </Grid>
      </Grid>
    </>
  );
}
