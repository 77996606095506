import React , { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import CustomToolbar from "./CustomToolbar";
import {fetcher} from '../../utils/fetchBData'

// msal auth
import { useMsal} from "@azure/msal-react"
import {get_access_txt, check_read_only} from '../../utils/Utils';

// components
import PageTitle from "../../components/PageTitle";

import {
  Typography,
} from "@material-ui/core";

const handleEdit = (id, e) => {
    e.preventDefault();
    window.location = "/#/addcustomer?id="+id;
  }

export default function Tables() {
  const [customers, setCustomers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { accounts, instance } = useMsal();
  var read_only_flag = check_read_only(accounts);
  var allow_edit = read_only_flag ? false : true;
  const columns = [
 {
  name: "id",
  label: "Id",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            { allow_edit ? ( <a href={"#"} onClick={(e) => handleEdit(value, e)} title='Edit Customer'>{value.substring(0, 4)+"..."}</a> ) : (value.substring(0, 4)+"...") }
          </div>
        )
  }
 },
 {
  name: "name",
  label: "Name",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "autodelivery_timeout",
  label: "Autodelivery Timeout",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "emails",
  label: "Emails",
  options: {
   filter: true,
   sort: false,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {(tableMeta.rowData[3] != '') ?
           String(value).length > 25 ?
           String(value).substring(0, 25)+"..."
           : value 
            : 'N/A'}
          </div>
        )
  }
 },
 {
  name: "cts_enabled",
  label: "CTS Enabled",
  options: {
   filter: true,
   sort: false,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value ? value && value === true ? 'True' : 'False'
            : 'False'} 
          </div>
        )
  }
 },
 {
  name: "contrive_enabled",
  label: "Contrive Enabled",
  options: {
   filter: true,
   sort: false,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value === true ? 'True' : ''}
            {value === false ? 'False' : ''}
          </div>
        )
  }
 },
/**{
  name: "id",
  label: "Edit",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href={"#"} onClick={(e) => handleEdit(value, e)} title='Edit Customer'><EditIcon/></a>
          </div>
        )}
 }
**/
];
  let url = process.env.REACT_APP_CONTRIVE_API_URL+"customer"
  const fetchData = () => {
    setIsLoading(true)
    fetcher(url, {})
      .then(data => {
        setIsLoading(false)
        setCustomers(data)
      })
      .catch(error => {
            console.error('There was an error!', error);
  })
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
      <PageTitle title="Customers View" />
      <Typography variant="h6"><a href = {process.env.REACT_APP_CMDB_URL} target="_blank"> Visit Cmdb</a></Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
           {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : (
          <MUIDataTable
            title="Customers List"
            data={customers.data}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: false,
              customToolbar: () => {
        return (
          <React.Fragment>
          { allow_edit ? (
          <CustomToolbar />
          ) : null
          }
          </React.Fragment>
        );
      }
            }}
          />
          )}
        </Grid>
      </Grid>
    </>
  );
}
