import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import {
  Typography,
  Button,
} from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';
import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {fetcher} from '../../utils/fetchBData'

const styledeco = {
    display: 'block',
    width: '20%',
    padding: '.375rem .75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
}

export default function AddRegion() {
   const [resp, setResp] = useState({});
   const [name, setName] = useState("");
   const [code, setCode] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const search = useLocation().search;
   let editid = new URLSearchParams(search).get("id");
   if (editid == null){
       editid = ''
   }
   const handleReq = async (id, title, requestOptions, url) => {
        setIsLoading(true)
          console.log(requestOptions);
          fetcher(url, requestOptions)
          .then(data => {
            setIsLoading(false)
            console.log(data)
            setResp(data)
            setName(data.name)
            setCode(data.code)
            let msg = data.message;
            let msgtype = 'warning';
            if (data.success === 1){
                msg = data.message;
                msgtype = 'success';
            }

            Store.addNotification({
              title: title,
              message: msg,
              type: msgtype,
              insert: "top",
              container: "top-center",
              width: 500,
              dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
              }
          });

   }).catch(error => {
            console.error('There was an error!', error);
          })
   }
   const cancelFunc = (e) => {
   window.location = "/#/regions"
   }

   const addFunc = (e) => {
        //e.preventDefault();
        if(document.getElementById("code").value == ''){
            alert("Please enter code.");
            return false;
        }
        if(document.getElementById("name").value == ''){
            alert("Please enter name.");
            return false;
        }
        var msgTxt = "Added!";
        if (editid){
            msgTxt = "Updated!";
            }
        const requestOptions = {
        method: 'POST',
        body: JSON.stringify({'name': name, 'code': code, 'id': editid}),
        };
        let url = process.env.REACT_APP_CONTRIVE_API_URL+"add_region";
        console.log(url);
        handleReq('', msgTxt, requestOptions, url);
        window.location = "/#/regions";
       }
 
   const fetchData = (id) => {
    setIsLoading(true)
    let url = process.env.REACT_APP_CONTRIVE_API_URL+"add_region?id="+id;
    const requestOptions = {};
    fetcher(url, requestOptions)
      .then(data => {
        console.log(data);
        setIsLoading(false)
        setResp(data)
        setName(data.name)
        setCode(data.code)
      })
      .catch(error => {
            console.error('There was an error!', error);
  })
  }
  useEffect(() => {
       fetchData(editid);
  }, []);
  return (
    <div className="App">
      {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : ( 
      <React.Fragment>
      <Typography variant="h2">{resp.buttontext} Region</Typography>
      </React.Fragment>
      )}
        {isLoading ? (
        null //<p><CircularProgress color="inherit" /></p> // no circular progress as coming from abv
      ) : (
      <React.Fragment>
      <form method="POST" id="regionform" name="regionform">

     <div>Code: <input type="text" style={styledeco} id="code" name="code" value={code} onChange={e => setCode(e.target.value)} /></div>
     <div>Name: <input type="text" style={styledeco} id="name" name="name" value={name} onChange={e => setName(e.target.value)} /></div>
      <br />
        <Button title="Add Region" onClick={(e) => addFunc(e)}  variant="contained" color="primary">
          {resp.buttontext} 
        </Button>
        &nbsp;&nbsp;<Button title="Cancel" onClick={(e) => cancelFunc(e)} variant="contained" color="primary">
          Cancel
        </Button>
    </form>
    </React.Fragment>
    )}
    </div>
  );
}


