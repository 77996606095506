import React, { useState, useEffect } from 'react';
import $ from 'jquery'
import { Editor } from "@tinymce/tinymce-react";
import {
  Typography,
  Button,
} from "@material-ui/core";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from "react-hook-form";
import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {fetcher} from '../../utils/fetchBData'

const styledeco = {
    display: 'block',
    width: '100%',
    padding: '.375rem .75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
}

export default function AddNotification() {
   const [resp, setResp] = useState({});
   const [inccon, setIncContent] = useState("");
   const [name, setName] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const handleReq = (id, title, requestOptions, url) => {
        setIsLoading(true)
          fetcher(url, requestOptions)
            .then(data => {
            setIsLoading(false)
            setResp(data)
            setIncContent(data.content)
            setName(data.name)
            let msg = data.message;
            let msgtype = 'warning';
            if (data.success === 1){
                msg = data.message;
                msgtype = 'success';
            }

            Store.addNotification({
              title: title,
              message: msg,
              type: msgtype,
              insert: "top",
              container: "top-center",
              width: 500,
              dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
              }
          });

          }
          )
          .catch(error => {
            console.error('There was an error!', error);
          })
   }

   const addFunc = (e) => {
        //e.preventDefault();
        if(document.getElementById("name").value === ''){
            alert("Please enter name.");
            return false;
        }
        if(inccon === ''){
            alert("Please enter content.");
            return false;
        }
        const pdata = new FormData();
        pdata.append("content", inccon);
        pdata.append("name", $('#name').val());
        const requestOptions = {
        method: 'POST',
        body: JSON.stringify({'content': inccon, 'name': $('#name').val()}),
        };
        let url = process.env.REACT_APP_CONTRIVE_API_URL+"add_notification";
        handleReq("", 'Added!', requestOptions, url);
        window.location = "/#/notifications"
       }
 
   const onCancelFunc = (e) => {
     e.preventDefault();
     window.location = "/#/notifications"
   }
    
   const handleEditorChange = (content, editor) => {
     setIncContent(content);
   }

   const unsignedUploadPreset = 'adz8s31b';  
   const fetchData = () => {
    setIsLoading(true)
    let url = process.env.REACT_APP_CONTRIVE_API_URL+"add_notification";
    fetcher(url, {})
      .then(data => {
        setIsLoading(false)
        setResp(data)
        setIncContent(data.content)
        setName(data.name)
      })
      .catch(error => {
            console.error('There was an error!', error);
  })
  }
  useEffect(() => {
       fetchData();
  }, []);
  return (
    <div className="App">
      {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : ( 
      <React.Fragment>
      <Typography variant="h2">Add notification</Typography>
      </React.Fragment>
      )}
     <React.Fragment>
     <form method="POST" id="addform" name="addform">
     <div>Name: <input type="text" style={styledeco} id="name" name="name" value={name} onChange={e => setName(e.target.value)} /></div>
      Report Content:<Editor
        apiKey="qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"
        initialValue={resp.content}
        onEditorChange={handleEditorChange}
        init={{
        height: 400,
        toolbar: "code insertfile undo redo image | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link imageupload",
        plugins: "advlist autolink lists link image charmap print anchor searchreplace visualblocks code fullscreen code insertdatetime media table contextmenu paste",
        init_instance_callback : function() {
        var freeTiny = document.querySelector('.tox .tox-notification--in');
        freeTiny.style.display = 'none';
        //var freeTiny1 = document.querySelector('.tox-notification .tox-notification--in .tox-notification--warning');
        //freeTiny1.style.display = 'none';
        },
        forced_root_block:"",
        images_upload_credentials: true,
        image_title: true,
        automatic_uploads: true,
        file_picker_types: 'image',
        file_picker_callback: function (cb, value, meta) {
              var input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');
              var url = process.env.REACT_APP_CONTRIVE_API_URL+"image_uploader";
              var xhr = new XMLHttpRequest();
              var fd = new FormData();
              xhr.open('POST', url, true);

              input.onchange = function () {
                var file = this.files[0];
                var reader = new FileReader();
                xhr.onload = function () {
                  if (xhr.status === 200) {
                    // File uploaded successfully
                    var response = JSON.parse(xhr.responseText);
                    var url = response.location;
                    cb(url, { title: 'tmp.png' });

                  }
                };

                reader.onload = function () {
                  var id = 'blobid' + (new Date()).getTime();
                  var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                  var base64 = reader.result.split(',')[1];

                  var blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);
                  // call the callback and populate the Title field with the file name
                  fd.append('upload_preset', unsignedUploadPreset);
                  fd.append('tags', 'browser_upload');
                  fd.append('file', blobInfo.blob(), blobInfo.filename());

                  xhr.send(fd);

                };

                reader.readAsDataURL(file);
              };

              input.click();
            },
            images_upload_handler: (blobInfo, success, failure) => {
              let data = new FormData();
              var reader = new FileReader();
              var url = process.env.REACT_APP_CONTRIVE_API_URL+"image_uploader";
              data.append('file', blobInfo.blob(), blobInfo.filename());
              data.append('upload_preset', unsignedUploadPreset);
              data.append('tags', 'browser_upload');
              axios.post(url, data, { headers: { 'Content-Type': 'multipart/form-data'}
                 })
                .then(function (res) {
                  //success(res.data.location)
                })
                .catch(function (err) {
                  //console.log(err)
                });
              reader.readAsDataURL(blobInfo.blob())
            }
      }}
      />
      <br />
        <Button title="Add Notification" onClick={(e) => addFunc(e)}  variant="contained" color="primary">
          Add Notification
        </Button>
        &nbsp;&nbsp;<Button title="Cancel" onClick={(e) => onCancelFunc(e)} variant="contained" color="primary">
          Cancel 
        </Button>
    </form>
    </React.Fragment>
    </div>
  );
}
