import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import $ from 'jquery'
import {
  Typography,
  Button,
} from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';
import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { closeFunc } from './utils.js'; 
import {fetcher} from '../../utils/fetchBData'

export default function EditBouncer() {
   const [boncon, setBonContent] = useState('');
   const [isLoading, setIsLoading] = useState(false);

   const updateandRegenerateFunc = (updatedata, e) => {
        if(document.getElementById("content").value === ''){
            alert("Please enter bouncer content.");
            return false;
        }else{
        const requestOptions = {
        method: 'POST',
        body: JSON.stringify({'content': boncon, 'editbouncer_andreplay': "1"}),
        };
        setIsLoading(true)
        let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/edit_bouncer/"+updatedata;
          fetcher(url, requestOptions)
            .then(data => {
            setIsLoading(false)
            setBonContent(JSON.stringify(data.content,  null, 2))
            let msg = data.message;
            let msgtype = 'warning';
            if (data.success === 1){
                msg = data.message;
                msgtype = 'success';
            }

            Store.addNotification({
              title: "Updated!",
              message: msg,
              type: msgtype,
              insert: "top",
              container: "top-center",
              width: 500,
              dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
              }
          });

   }
   )
          .catch(error => {
            console.error('There was an error!', error);
          })
   }
   }

   const getOriginalbouncerFunc = (updatedata, e) => {
        const requestOptions = {
        method: 'POST',
        body: JSON.stringify({'getorigbouncer_hidden': "1"}),
        };
        setIsLoading(true)
        let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/edit_bouncer/"+updatedata;
          fetcher(url, requestOptions)
            .then(data => {
            setIsLoading(false)
            setBonContent(JSON.stringify(data.content,  null, 2))
            let msg = data.message;
            let msgtype = 'warning';
            if (data.success === 1){
                msg = data.message;
                msgtype = 'success';
            }

            Store.addNotification({
              title: "Updated!",
              message: msg,
              type: msgtype,
              insert: "top",
              container: "top-center",
              width: 500,
              dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
              }
          });

   }
   )
          .catch(error => {
            console.error('There was an error!', error);
          })

   }

   const updateFunc = (updatedata, e) => {
        if(document.getElementById("content").value === ''){
            alert("Please enter bouncer content.");
            return false;
        }else{
        const requestOptions = {
        method: 'POST',
        body: JSON.stringify({'content': boncon}),
        };
        setIsLoading(true)
        let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/edit_bouncer/"+updatedata;
          fetcher(url, requestOptions)
            .then(data => {
            setIsLoading(false)
            setBonContent(JSON.stringify(data.content,  null, 2))
            let msg = data.message;
            let msgtype = 'warning';
            if (data.success === 1){
                msg = data.message;
                msgtype = 'success';
            }

            Store.addNotification({
              title: "Updated!",
              message: msg,
              type: msgtype,
              insert: "top",
              container: "top-center",
              width: 500,
              dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
              }
          });

   }
   )
          .catch(error => {
            console.error('There was an error!', error);
          })
       }
   }
 
   const search = useLocation().search;
   const editid = new URLSearchParams(search).get("id");
   const fetchData = (id) => {
    setIsLoading(true)
    let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/edit_bouncer/"+id;
    fetcher(url, {})
      .then(data => {
        setIsLoading(false)
        setBonContent(JSON.stringify(data.content,  null, 2))
      })
      .catch(error => {
            console.error('There was an error!', error);
  })
  }
  useEffect(() => {
       fetchData(editid);
  }, []);
  return (
    <div className="App">
      {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : ( 
     
      <React.Fragment>
      <Typography variant="h2">Edit Bouncer Content </Typography>
      <form method="POST" id="editbouncer" name="editbouncer">
      <textarea id = 'content' name='content' rows='35' cols='88' value={boncon} onChange={e => setBonContent(e.target.value)} />
      <input type="hidden" name="editbouncer" id="editbouncer" value="0" />
    <input type="hidden" name="getorigbouncer_hidden" id="getorigbouncer_hidden" value="0" />
    <input type="hidden" name="editbouncer_andreplay" id="editbouncer_andreplay" value="0" />
      <br />
        <Button title="Update Bouner" onClick={(e) => updateFunc(editid, e)}  variant="contained" color="primary">
          Update Bouncer
        </Button>
        &nbsp;&nbsp;<Button title="Update and regenrate Inc" onClick={(e) => updateandRegenerateFunc(editid, e)}  variant="contained" color="primary">
          Update and regenrate Inc
        </Button>
        &nbsp;&nbsp;<Button title="Get Original Bouncer" onClick={(e) => getOriginalbouncerFunc(editid, e)} variant="contained" color="primary">
          Get Original Bouncer
        </Button>
        &nbsp;&nbsp;<Button title="Close" onClick={(e) => closeFunc(editid, e)} variant="contained" color="primary">
         Close 
        </Button>
    </form>
    </React.Fragment>
    )}
    </div>
  );
}
