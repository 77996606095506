import React , { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import { Store } from 'react-notifications-component'
import dateFormat from "dateformat";

// components
import PageTitle from "../../components/PageTitle";

import { useMsal } from "@azure/msal-react";
import {fetcher} from '../../utils/fetchBData'

import {
  PictureAsPdf as PictureAsPdfIcon,
} from "@material-ui/icons";

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

function get_revision(value, idval){
    var val =  value.split("/").pop();
    var revval = val.replace(idval+"_", "").replace(".html", "").replace(idval, "");
    if (revval == '') {
        revval = 'Default'
    }
    return revval;
    }

export default function Tables() {
  const [incs, setInc] = useState([])
  const [expdata, setExportData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { accounts } = useMsal();

  const fetchData = () => {
    setIsLoading(true)
    let url = process.env.REACT_APP_CONTRIVE_API_URL+"sentincidents";
    let data = fetcher(url, accounts).then(data => {
    console.log(data);
    setInc(data)
    setIsLoading(false)
  }) 
  }

  const exportDataFunc = async (id, e) => {
    e.preventDefault();
    let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/get_bouncer/"+id+"/sent";
    var dd = await fetcher(url, {})
            .then(data => {
            setExportData(data.content)
            return data.content;
          }
          )
          .catch(error => {
            console.error('There was an error in fetching bouncer data!', error);
          })

   
  
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(dd)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = id+"_bounceralert.json";

    link.click();
  };

  const handleReq = (id, title, requestOptions, url, shownotification=true) => {

        setIsLoading(true)
          fetcher(url, requestOptions)
            .then(data => {
            setInc(data)
            setIsLoading(false)
            let msg = data.message;
            let msgtype = 'warning';
            if (data.success === 1){
                msg = data.message;
                msgtype = 'success';
            }
            if (shownotification) {
            Store.addNotification({
              title: title,
              message: msg,
              type: msgtype,
              insert: "top",
              container: "top-center",
              width: 500,
              dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
              }
          });
          }
          }
          )
          .catch(error => {
            console.error('There was an error!', error);
          })
   }

   const columns = [
 {
  name: "idhash",
  label: "Id",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href={"/#/viewpdf?pdf="+value+"&loc=sent"} title={value} target="_blank" rel="noreferrer">{value.substring(0, 4)+"..."}</a>
          </div>
        )
  }
 },
 {
  name: "signature",
  label: "EventName",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "customer",
  label: "Customer",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "src_ip",
  label: "Source Ip",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {(value != "") ?
            value
            : 'N/A'}
          </div>
        )
  }
  },
 {
  name: "severity",
  label: "Severity",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "report_ref",
  label: "Report Revision",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
          {get_revision(value, tableMeta.rowData[0])}
          </div>
        )
  }
 },
 {
  name: "trigger_at",
  label: "TriggerDate",
  options: {
   filter: true,
   sort: false,
  }
 },
 {
  name: "activity_at_ts",
  label: "Sent Date",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <p>
            {dateFormat(value*1000, "yyyy-mm-dd hh:mm:ss")}
          </p>
        )

          
  }
 },
 {
  name: "idhash",
  label: "View Pdf",
  options: {
   filter: false,
   sort: false,
   //customBodyRender: (value, tableMeta, updateValue) => {
   //         return (
   //           <a href={value}>aaa</a>
   //         );
   //       }
  customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href={"/#/viewpdf?pdf="+value+"&loc=sent"} target="_blank" rel="noreferrer"><PictureAsPdfIcon /></a>
          </div>
        )
  }
 },
 {
  name: "idhash",
  label: "Download Bouncer",
  options: {
   filter: false,
   sort: false,
   //customBodyRender: (value, tableMeta, updateValue) => {
   //         return (
   //           <a href={value}>aaa</a>
   //         );
   //       }
  customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href="#" onClick={(e) => exportDataFunc(value, e)} target="_blank" rel="noreferrer"><CloudDownloadIcon /></a>
          </div>
        )
  }
 }
];

    const confirmcheckdel = (checkedarray, rowdata) => {
      for (let i = 0; i < checkedarray.data.length; i++) {
            var shownotification = false;
            if (i === checkedarray.data.length - 1) {
                shownotification = true;
            }
            var id = rowdata[checkedarray.data[i]['dataIndex']]['idhash']
            let url = process.env.REACT_APP_CONTRIVE_API_URL+"sentincidents/confirm/"+id+"?incmode=manual";
            handleReq(id, 'Deleted!', {}, url, shownotification)
        }
    }

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
      <PageTitle title="Sent (Manually)Incidents View" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
           {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : (
          <MUIDataTable
            title="Sent (Manually)Incident List"
            data={incs.incidents}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: false,
              onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      },
      onRowsDelete: (rowsDeleted, data, newTableData) => {
        var ask = window.confirm("Are you sure you want to delete selected Incidents?");
      if (ask) {
        confirmcheckdel(rowsDeleted, incs.incidents);
        } else {
        return false;
        }
      }
            }}
          />
           )}
        </Grid>
      </Grid>
    </>
  );
}
