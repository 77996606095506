import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'
//icons
import {
  mdiTwitter as TwitterIcon,
  mdiLinkedin as mdiLinkedinIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
//import { msalInstance } from '../Header'

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import AddNotification from "../../pages/addnotification";
import EditNotification from "../../pages/editnotification";
import SentNotifications from "../../pages/sentnotifications";
import AggIncidents from "../../pages/aggincidents";
import SIncidentsa from "../../pages/sentincidentsa";
import SIncidentsm from "../../pages/sentincidentsm";
import DIncidents from "../../pages/discardincidents";
import QIncidents from "../../pages/incidents";
import UQIncidents from "../../pages/quincidents";
import Regions from "../../pages/regions";
import AddRegion from "../../pages/regions/AddRegion";
import Platforms from "../../pages/platforms";
import AddPlatform from "../../pages/platforms/AddPlatform";
import Customers from "../../pages/customers";
import AddCustomer from "../../pages/customers/AddCustomer";
import BulkUpdateCustomer from "../../pages/customers/BulkUpdateCustomer";
import ViewPdf from "../../pages/viewpdf";
import EditInc from "../../pages/editinc";
import EditBouncer from "../../pages/editbouncer";
import LogsReplay from "../../pages/logsreplay";
import FaereStatus from "../../pages/fstatus";
import { createContext, useState } from "react";

// context
import { useLayoutState } from "../../context/LayoutContext";

// msal auth
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react"
import { PublicClientApplication, AccountInfo, InteractionType } from "@azure/msal-browser"
import { msalConfig } from "../../utils/msalConfig"

export const ThemeContext = createContext(null);

export const msalInstance = new PublicClientApplication(msalConfig)

function Layout(props) {
  var classes = useStyles();

  const setActiveAccount = (account: AccountInfo) => {
    msalInstance.setActiveAccount(account)
  }

  msalInstance.handleRedirectPromise().then((result) => {
  if (result && result.account) {
    setActiveAccount(result.account)
  }
  })

  const accounts = msalInstance.getAllAccounts()
  if (accounts && accounts.length > 0) {
    setActiveAccount(accounts[0])
  }

  // global
  var layoutState = useLayoutState();
  const [theme, setTheme] = useState("dark");

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
      >
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
    <div className={classes.root} id={theme}>
        <>
          {props.location.pathname.includes("/editbouncer") ? (
           null 
) : <Header history={props.history} />}
          {props.location.pathname.includes("/editbouncer") ? (
         null
) : <Sidebar />}

          
          <ReactNotifications />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/typography" component={Typography} />
              <Route path="/aggincidents" component={AggIncidents} />
              <Route path="/qincidents" component={QIncidents} />
              <Route path="/quincidents" component={UQIncidents} />
              <Route path="/sentincidentsa" component={SIncidentsa} />
              <Route path="/sentincidentsm" component={SIncidentsm} />
              <Route path="/discardincidents" component={DIncidents} />
              <Route path="/notifications" component={Notifications} />
              <Route path="/addnotification" component={AddNotification} />
              <Route path="/editnotification" component={EditNotification} />
              <Route path="/sentnotifications" component={SentNotifications} />
              <Route path="/viewpdf" component={ViewPdf} />
              <Route path="/editinc" component={EditInc} />
              <Route path="/editbouncer" component={EditBouncer} />
              <Route path="/fstatus" component={FaereStatus} />
              <Route path="/logsreplay" component={LogsReplay} />
              <Route
                exact
                path="/ui"
                render={() => <Redirect to="/ui/icons" />}
              />
              <Route path="/platforms" component={Platforms} />
              <Route path="/addplatform" component={AddPlatform} />
              <Route path="/regions" component={Regions} />
              <Route path="/addregion" component={AddRegion} />
              <Route path="/customers" component={Customers} />
              <Route path="/addcustomer" component={AddCustomer} />
              <Route path="/bulkupdatecustomer" component={BulkUpdateCustomer} />
            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <div>
                <Link
                  color={'primary'}
                  href={'https://www.security.ntt/'}
                  target={'_blank'}
                  className={classes.link}
                >
                  <p>Copyright &copy; NTT Security Holdings 2023</p> 
                </Link>
              </div>
              <div>
                <Link
                  href={'https://www.linkedin.com/company/ntt-security-holding'}
                  target={'_blank'}
                >
                  <IconButton aria-label="linkedin">
                    <Icon
                      path={mdiLinkedinIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://mobile.twitter.com/nttsecurity'}
                  target={'_blank'}
                >
                  <IconButton aria-label="twitter">
                    <Icon
                      path={TwitterIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
              </div>
            </Box>
          </div>
        </>
    </div>
    </ThemeContext.Provider>
    </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default withRouter(Layout);
