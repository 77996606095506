import React , { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import { Store } from 'react-notifications-component'

// components
import PageTitle from "../../components/PageTitle";
import {
  PictureAsPdf as PictureAsPdfIcon,
} from "@material-ui/icons";
import DeleteIcon from '@mui/icons-material/Delete';
import {fetcher} from '../../utils/fetchBData'

// msal auth
import { useMsal} from "@azure/msal-react"
import {get_access_txt, check_read_only} from '../../utils/Utils';


export default function Tables() {
  const [resp, setResp] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { accounts, instance } = useMsal();
  var read_only_flag = check_read_only(accounts);
  var allow_edit = read_only_flag ? false : true;
  const handleReq = (id, title, requestOptions, url, shownotification=true) => {

        setIsLoading(true)
          fetcher(url, requestOptions)
            .then(data => {
            setIsLoading(false)
            console.log(data);
            setResp(data)
            let msg = data.message;
            let msgtype = 'warning';
            if (data.success === 1){
                msg = data.message;
                msgtype = 'success';
            }
            if (shownotification) {
            Store.addNotification({
              title: title,
              message: msg,
              type: msgtype,
              insert: "top",
              container: "top-center",
              width: 500,
              dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
              }
          });
          }
          }
          )
          .catch(error => {
            console.error('There was an error!', error);
          })
   }

  const confirmdel = (id, e) => {
      e.preventDefault();
      var ask = window.confirm("Are you sure you want to delete this sent notification?");
      if (ask) {
          let url = process.env.REACT_APP_CONTRIVE_API_URL+"sentnotifications/confirm/"+id;
          handleReq(id, 'Deleted!', {}, url)
      }else {
      return false; 
      }
    }

  let url = process.env.REACT_APP_CONTRIVE_API_URL+"sentnotifications";
  const fetchData = () => {
    setIsLoading(true)
    fetcher(url, {})
      .then(data => {
        console.log(data)
        setIsLoading(false)
        setResp(data)
      })
      .catch(error => {
            console.error('There was an error!', error);
  })
  }
  const columns = [
 {
  name: "id",
  label: "Id",
  options: {
   filter: true,
   sort: true,
  }
 },
{
  name: "customer",
  label: "Customer",
  options: {
   filter: true,
   sort: true,
  }
 },
{
  name: "notification_type",
  label: "Type",
  options: {
   filter: true,
   sort: true,
  }
 },
{
  name: "emailsubject",
  label: "Email Subject",
  options: {
   filter: true,
   sort: true,
  }
 },
{
  name: "emailcontent",
  label: "Email Content",
  options: {
   filter: true,
   sort: true,
  }
 },
{
  name: "emails",
  label: "Emails",
  options: {
   filter: true,
   sort: true,
  }
 },
{
  name: "activity_at",
  label: "Datetime",
  options: {
   filter: true,
   sort: true,
  }
 },
{
  name: "id",
  label: "View Pdf",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
           <a href={"/#/viewpdf?pdf="+value+"&loc=sentnotification"} title={"Notification Report:"+value} target="_blank" rel="noreferrer"><PictureAsPdfIcon/></a>
          </div>
        )}
 },
];
if (allow_edit){
 
  columns.push({
  name: "id",
  label: "Delete",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
           <a href='' onClick={(e) => confirmdel(value, e)} title={"Notification Delete "+value}><DeleteIcon/></a>
          </div>
        )}
 });
}
  useEffect(() => {
    fetchData()
  }, [])
  return (
     <>
      <PageTitle title="Sent Notifications View" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : (
          <MUIDataTable
            title="Sent Notifications List"
            data={resp.notifications}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: allow_edit,
            }}
          />
           )}
        </Grid>
      </Grid>
    </>
  );
}
