export const msalConfig = {
    auth: {
        clientId: 'afd45a69-5858-4bf8-b395-2d6d06a7c5cc',
        authority: 'https://login.microsoftonline.com/8fa58a73-2ed8-4c41-8dc7-94bb010b4f94',
        redirectUri: '/',
    },
}

export const loginRequest = {
    scopes: ["User.Read"]
}

export const tokenRequest = {
    scopes: ["afd45a69-5858-4bf8-b395-2d6d06a7c5cc/.default"]
}
