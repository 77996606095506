import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import { Typography, } from "../Wrappers";
import Notification from "../Notification/Notification";

// msal auth
import { useMsal} from "@azure/msal-react"

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const notifications = [
  {
    id: 0,
    color: "secondary",
    type: "notification",
    message: "There is 8 queued incidents...",
  }
];

function get_access_txt(accounts, type='Platform'){
        var access_txt = '';
        try {
            var tmp_txt = "";
            var roles = accounts[0]?.idTokenClaims.roles;
            for (let i=0; i < roles.length; i++) {
                let roles_coming = roles[i]
                if (roles_coming.includes("#")){
                    roles_coming = roles_coming.split("#")
                    roles_coming = roles_coming[1]
                }
                let role_split = roles_coming.split("@")
                if (type.toLowerCase() === role_split[0].toLowerCase()) 
                    tmp_txt = tmp_txt+role_split[1]+","
            }
            return tmp_txt.substring(0, tmp_txt.length - 1);
        }catch (error) {
            console.error(error);
            return access_txt;
        } 
    }
export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const { accounts, instance } = useMsal()
  const handleLogoutRedirect = () => {
      instance.logoutRedirect().catch((error) => console.log(error));
  };
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
 <img
          src="./ntt-logo.png"
          width="120px"
          height="43px" 
          align="middle"
          alt="logo"
        />
        <Typography variant="h3" weight="bold" className={classes.logotype}>
        Contrive
        </Typography>
        <div className={classes.grow} />
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setNotificationsMenu(e.currentTarget);
            setIsNotificationsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
        </IconButton>
        {accounts[0]?.name|| 'Unknown'}<IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {notifications.map(notification => (
            <MenuItem
              key={notification.id}
              onClick={() => setNotificationsMenu(null)}
              className={classes.headerMenuItem}
            >
              <Notification {...notification} typographyVariant="inherit" />
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h8" weight="bold">
              Name: {accounts[0]?.name || 'Unknown'} 
            </Typography>
            <Typography variant="h8" weight="bold">
              Email: {accounts[0]?.username || 'Unknown'}      
            </Typography>
            { get_access_txt(accounts, 'Platform') ?
            <Typography variant="h8" weight="bold">
              Platform Access: {get_access_txt(accounts, 'Platform')}
            </Typography> : ''
            }
            { get_access_txt(accounts, 'Region') ?
            <Typography variant="h8" weight="bold">
              Region Access: {get_access_txt(accounts, 'Region')}
            </Typography> : ''
            }
            { get_access_txt(accounts, 'Customer') ?
            <Typography variant="h8" weight="bold">
              Customer Access: 'Custom'
            </Typography> : ''
            }
          </div>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={(e) => handleLogoutRedirect(e)}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
