import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import {fetcher} from '../../utils/fetchBData';

const PDFView = () => {
        const [pdfcon, setPdfC] = useState("")
        const [isLoading, setIsLoading] = useState(false)
        const search = useLocation().search;
        const pdf = new URLSearchParams(search).get("pdf");
        const loc = new URLSearchParams(search).get("loc");

 
  const fetchData = (id, locationv) => {
    setIsLoading(true)
    let url = process.env.REACT_APP_CONTRIVE_API_URL+"incidents/viewpdf/"+id+"/"+locationv;
    fetcher(url, {})
      .then(data => {
        setIsLoading(false)
        setPdfC(data.data)
      })
      .catch(error => {
            console.error('There was an error!', error);
  })
  } 
  useEffect(() => {
       fetchData(pdf, loc);
  }, []);
  return (
      <>
      {isLoading ? (
        <p><CircularProgress color="inherit" /></p>
      ) : (
      <iframe width="100%" height="100%"
        title="Security Incident Report"
        src={pdfcon}
      />
       )}
      </>
  );
}

export default PDFView;
