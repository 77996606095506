import React , { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { useTheme } from "@material-ui/styles";
import CircularProgress from '@mui/material/CircularProgress';
import {fetcher} from '../../../utils/fetchBData'

export default function ApexLineChartDiscardInc() {
  var theme = useTheme();
  const [consumptionpd, setConsumptionPerDay] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  let url = process.env.REACT_APP_CONTRIVE_API_URL+"apextimelinestats/discard/cust";
  const fetchData = () => {
    setIsLoading(true)
    const requestOptions = {};
    let data = fetcher(url, requestOptions).then(data => {
        setConsumptionPerDay(data.data)
        setIsLoading(false)
      })
      .catch(error => {
            console.error('There was an error!', error);
  });       
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <React.Fragment>
    {isLoading ? (
       <p><CircularProgress color="inherit" /></p>
      ) : ( consumptionpd ? ( 
        <ApexCharts
        options={themeOptions(theme, consumptionpd)}
        series={consumptionpd.series}
        type="line"
        height={220}
        width= {1300}
       // zoom = { enabled= false }
      />
   ) : ('') ) }
    </React.Fragment>
  );
}

// ###########################################################
function themeOptions(theme, consumptionpd) {
  return {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "datetime",
      categories: consumptionpd.dates
    },
    tooltip: {
      x: {
        //format: "dd/MM/yy HH:mm",
        format: "yyyy/MM/dd",
      },
    },
    //fill: {
    //  colors: [theme.palette.primary.light, theme.palette.success.light],
    //},
    //colors: [theme.palette.primary.main, theme.palette.success.main],
    chart: {
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
  };
}
